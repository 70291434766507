<template>
  <div>
    <div class="descriptor-container">
      <div
        v-if="is_evaluating && evaluating_user && institution"
        class="descriptor-checkbox"
      >
        <b-checkbox
          v-model="score_selected"
          :disabled="
            score_selected == true ||
            (institution.actual_period != null &&
              institution.actual_period.id != period_id)
          "
          @change="saveRubricScore"
        ></b-checkbox>
      </div>
      <div class="descriptor-div">
        <template v-if="allows_crud">
          <StyledTextArea
            v-model="observation_descriptor.text"
            @input="debounceSave"
            :state="true"
          ></StyledTextArea>
        </template>
        <template v-else>{{ observation_descriptor.text }}</template>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "DescriptorCell",
  components: {
    StyledTextArea: () => import("@/components/reusable/StyledTextArea"),
  },
  props: {
    RubricDescriptor: {
      type: Object,
    },
    observation_instance_id: {
      type: Number,
      required: true,
    },
    observation_achievement_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    is_evaluating: {
      type: Boolean,
      default: false,
    },
    evaluating_user: {
      type: Object,
    },
    period_id: {
      type: Number,
    },
  },
  data() {
    return {
      observation_descriptor: {
        id: this.RubricDescriptor
          ? this.RubricDescriptor.id
          : generateUniqueId(),
        observation_achievement: this.RubricDescriptor
          ? this.RubricDescriptor.observation_achievement
          : this.observation_achievement_id,
        observation_instance: this.RubricDescriptor
          ? this.RubricDescriptor.observation_instance
          : this.observation_instance_id,
        text: this.RubricDescriptor ? this.RubricDescriptor.text : "",
      },
      score_selected: false,
    };
  },
  computed: {
    ...mapGetters({
      rubricDescriptors: names.OBSERVATION_DESCRIPTORS,
      rubricScores: names.OBSERVATION_SCORES,
      institution: "getInstitution",
      users: names.USERS,
    }),
    rubricDescriptor() {
      return this.rubricDescriptors.find(
        (x) =>
          x.observation_instance == this.observation_instance_id &&
          x.observation_achievement == this.observation_achievement_id
      );
    },
    rubricScore() {
      if (!this.evaluating_user) return null;
      return this.rubricScores.find(
        (x) =>
          x.observation_instance == this.observation_instance_id &&
          x.student == this.evaluating_user.id
      );
    },
    student() {
      // const student = this.users.find(x => x.id == this.evaluating_user_id);
      // return student;
      return this.evaluating_user;
    },
  },
  methods: {
    saveRubricScore() {
      if (this.rubricScore) {
        const payload = {
          observation_score_id: this.rubricScore.id,
          item: {
            observation_achievement: this.observation_achievement_id,
          },
        };
        this.$store
          .dispatch(names.PATCH_OBSERVATION_SCORE, payload)
          .then((response) => {
            toast("Puntaje actualizado.");
            this.$emit("updateAutomatedScore");
            this.$emit("updated", response);
          });
      } else {
        const observation_score = {
          observation_instance: this.observation_instance_id,
          observation_achievement: this.observation_achievement_id,
          student: this.evaluating_user.id,
        };
        this.$store
          .dispatch(names.POST_OBSERVATION_SCORE, observation_score)
          .then((response) => {
            toast("Puntaje actualizado.");
            this.$emit("updateAutomatedScore");
            this.$emit("created", response);
          });
      }
    },
    debounceSave() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.saveRubricDescriptor();
      }, 2000);
    },
    saveRubricDescriptor() {
      if (isNaN(this.observation_descriptor.id)) this.createRubricDescriptor();
      else this.patchRubricDescriptor();
    },
    createRubricDescriptor() {
      this.$store
        .dispatch(
          names.POST_OBSERVATION_DESCRIPTOR,
          this.observation_descriptor
        )
        .then((response) => {
          this.observation_descriptor = response;
          toast("Descriptor creado.");
        });
    },
    patchRubricDescriptor() {
      this.$store
        .dispatch(names.PATCH_OBSERVATION_DESCRIPTOR, {
          observation_descriptor_id: this.observation_descriptor.id,
          item: {
            text: this.observation_descriptor.text,
          },
        })
        .then(() => {
          toast("Descriptor actualizado.");
        });
    },
    checkSelected() {
      if (this.rubricScore && this.evaluating_user) {
        if (
          this.rubricScore.observation_achievement ==
            this.observation_achievement_id &&
          this.rubricScore.student == this.evaluating_user.id
        ) {
          this.score_selected = true;
          return;
        }
      }
      this.score_selected = false;
    },
  },
  watch: {
    rubricScore() {
      this.checkSelected();
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_RUBRIC_DESCRIPTOR_BY, {
        observation_instance_id: this.observation_instance_id,
        observation_achievement_id: this.observation_achievement_id,
      })
      .then((response) => {
        if (response) this.observation_descriptor = response;
      });
  },
  mounted() {
    this.checkSelected();
  },
};
</script>

<style scoped>
.descriptor-container {
  place-content: center;
  display: flex;
  flex-direction: row;
}
.descriptor-checkbox {
  width: fit-content;
  margin-left: 0.3em;
  height: 100%;
  align-self: center;
}
.descriptor-div {
  width: fit-content;
  margin-left: 0.3em;
  margin-right: 0.3em;
}
</style>